import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "placedOrder",
  initialState: {
    placedOrder: {},
  },
  reducers: {
    setPlacedOrder(state, action) {
      state.placedOrder = action.payload;
    },
  },
});

export const orderSliceActions = orderSlice.actions;

export default orderSlice.reducer;
