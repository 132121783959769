import ReactDOM from "react-dom";
import "./index.css";
import React, { lazy, Suspense } from "react";

import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.min.css";
// Redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThroughProvider } from "react-through";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
const LazyApp = lazy(() => import("./App"));

export const Store = store;
ReactDOM.render(
  <ThroughProvider>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <LazyApp />
        <ToastContainer theme="colored" autoClose={2000} />
      </Suspense>
    </Provider>
  </ThroughProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
