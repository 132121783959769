import styles from "./styles.module.css";
import Img from "../Img";

const Loader = ({ className, style }) => {
  return (
    <div className={`${styles.loader} ${className}`} style={{ ...style }}>
      <Img src="/assets/img/febysGifHighRes.gif" />
    </div>
  );
};

export default Loader;
