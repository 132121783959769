import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "Product Cart",
  initialState: {
    cartItems: [],
    quantity: 1,
    product: "",
    count: 0,
    productId: "",
    cartSubtotal: 0,
    voucherInfo: {},
  },
  reducers: {
    addItems(state, action) {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item.sku_id === newItem.sku_id
      );
      if (!existingItem) {
        state.cartItems.push(newItem);
      } else {
        existingItem.quantity++;
      }
    },

    setCartItems(state, action) {
      state.cartItems = action.payload;
    },

    initialCartCount(state, action) {
      state.count = action.payload;
    },

    cartCount(state) {
      state.count = state.count + 1;
    },

    cartSubtotalAmmount(state, action) {
      let sum = 0;
      for (let cartItem of state.cartItems) {
        sum += cartItem.price * cartItem.quantity;
      }
      state.cartSubtotal = sum;
    },

    decrementCartCount(state, action) {
      state.count = state.count - action.payload.quantity;
    },

    incrementQuantity(state, action) {
      const existingItem = state.cartItems.find(
        (item) => item.sku_id === action.payload
      );
      if (existingItem) {
        existingItem.quantity++;
      }
      state.count = state.count + 1;
    },

    decrementQuantity(state, action) {
      const existingItem = state.cartItems.find(
        (item) => item.sku_id === action.payload
      );
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity--;
        state.count -= 1;
      }
    },

    removeItem(state, action) {
      const updatedItemsAfterRemoval = state.cartItems.filter(
        (item) => item.sku_id !== action.payload
      );
      if (updatedItemsAfterRemoval) {
        state.cartItems = updatedItemsAfterRemoval;
      }
    },

    apicart(state, action) {
      state.cartItems = action.payload;
    },

    currentProductId(state, action) {
      state.productId = action.payload;
    },

    setVoucherCode(state, action) {
      state.voucherInfo = action.payload;
    },

    emptyCart(state) {
      state.cartItems = [];
      state.count = 0;
      state.productId = "";
      state.voucherInfo = {};
      state.cartSubtotal = 0;
    },
  },
});

export const cartSliceActions = cartSlice.actions;

export default cartSlice.reducer;
