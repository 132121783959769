import { createSlice } from "@reduxjs/toolkit";

const consumerSlice = createSlice({
  name: "Consumer",
  initialState: {
    activeConsumer: false,
    consumerprofile: {},
    searchString: "",
    selectedCategory: {},
    subscribedPackage: {},
    wishlistItems: [],
    consumerId: null,
  },
  reducers: {
    active(state) {
      state.activeConsumer = true;
    },
    inActive(state) {
      state.activeConsumer = false;
      state.subscribedPackage = {};
      state.consumerprofile = {};
    },
    consumerId(state, action) {
      state.consumerId = action.payload;
    },
    listing(state, action) {
      state.wishlistItems = action.payload;
    },
    setConsumerProfileData(state, action) {
      state.consumerprofile = action.payload;
    },
    serachingValue(state, action) {
      state.searchString = action.payload;
    },
    selectCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSubscribedPackage(state, action) {
      state.subscribedPackage = action.payload;
    },
  },
});

export const consumerSliceActions = consumerSlice.actions;

export default consumerSlice.reducer;
