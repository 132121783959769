import thunk from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
// Stores
import recentItems from "./RecentlyViewedItems";
import productCart from "./product-cart/index";
import consumerAuth from "./activeConsumer";
import placedOrder from "./placedOrder";
const STATE_NAME = "CartITEMS";

const persistedState = localStorage.getItem(STATE_NAME)
  ? JSON.parse(localStorage.getItem(STATE_NAME))
  : {};

const reducer = combineReducers({
  productCart,
  consumerAuth,
  placedOrder,
  recentItems,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  localStorage.setItem(STATE_NAME, JSON.stringify(store.getState()));
});

export default store;
