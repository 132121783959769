import { createSlice } from "@reduxjs/toolkit";

const recentItemSlice = createSlice({
  name: "Recent Items",
  initialState: {
    recentItems: [],
  },

  reducers: {
    addRecentItem(state, action) {
      const newItem = action.payload;

      const existingItem = state.recentItems.find(
        (item) => item.productId === newItem.productId
      );
      if (!existingItem) {
        state.recentItems.push(newItem);
        state.recentItems = [...state.recentItems].reverse().slice(0, 15);
      } else {
        const index = state.recentItems.findIndex(
          (item) => item.productId === newItem.productId
        );
        state.recentItems[index] = newItem;
        state.recentItems = [...state.recentItems].reverse().slice(0, 15);
      }
    },

    pushRecentItemsFromApi(state, action) {
      state.recentItems = action.payload;
    },
  },
});

export const recentItemSliceActions = recentItemSlice.actions;

export default recentItemSlice.reducer;
